@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&family=Montserrat:wght@500;700;800&display=swap');
* {
  box-sizing: border-box;
}

html {
  font-size: 12px;
}

html, body, #root, #root > div {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #F8F6F3 !important;
  color: #1A1A1A;
  --bs-body-color: #1A1A1A;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#modal-root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  overflow: auto;
  z-index: 1000;
  display: none;
  justify-content: center;
  align-items: center;
  /*padding: 4em;*/
}

.modal-content {
  background: unset !important;
  border: unset !important;
}

.modal-backdrop {
  background: unset !important;
  backdrop-filter: blur(5px) !important;
}

.modal-backdrop.show {
  opacity: 1 !important;
}
